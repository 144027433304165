import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { Loader, useGLTF, OrbitControls, PerspectiveCamera, Stars } from "@react-three/drei";
import "./styles/main.css";

function Model({ url, onMove, onOut }) {
	const { nodes, materials } = useGLTF(url);
	return (
		<group rotation={[0, 0, 0]} position={[0, 0, 0]} scale={[0.8, 0.8, 0.8]}>
			<group rotation={[0, 0, 0]}>
				<group position={[-5, 5, -7]}>
					<mesh
						style={{}}
						geometry={nodes.Sphere001_1.geometry}
						material={materials["lottery"]}
						onClick={() => window.open("https://marketplace.aspo.world/lottery/pool", "_blank").focus()}
						onPointerMove={onMove}
						onPointerOut={onOut}
					/>
					<mesh geometry={nodes.Sphere001_2.geometry} material={materials["mau chu lotty"]} />
				</group>
				<mesh geometry={nodes.Cube003.geometry} material={materials["Material.003"]} />
				<mesh geometry={nodes.Cube003_1.geometry} material={materials["Material.004"]} />
				<mesh geometry={nodes.Cube003_2.geometry} material={materials["Material.006"]} />
				<mesh geometry={nodes.Cube003_3.geometry} material={materials["scene.001"]} />
				<mesh geometry={nodes.Cube003_4.geometry} material={materials["Material.029"]} />
				<mesh
					geometry={nodes.Cube003_5.geometry}
					material={materials["cuc aspo dang truoc"]}
					onClick={() => window.open("https://web.aspo.world", "_blank").focus()}
					onPointerMove={onMove}
					onPointerOut={onOut}
				/>
				<mesh geometry={nodes.Cube003_6.geometry} material={materials["Material.031"]} />
				<mesh geometry={nodes.Cube003_7.geometry} material={materials["Material.032"]} />
				<mesh geometry={nodes.Cube003_8.geometry} material={materials["Material.033"]} />
				<mesh geometry={nodes.Cube003_9.geometry} material={materials["Material.034"]} />
				<mesh geometry={nodes.Cube003_10.geometry} material={materials["cuc aspo dang sau"]} />
				<mesh geometry={nodes.Cube003_11.geometry} material={materials["Material.002"]} />
				<mesh geometry={nodes.Cube003_12.geometry} material={materials["Material.005"]} />
				<mesh geometry={nodes.Cube003_13.geometry} material={materials["Material.009"]} />
				<mesh geometry={nodes.Cube003_14.geometry} material={materials["Material.010"]} />
				<mesh geometry={nodes.Cube003_15.geometry} material={materials["Material.011"]} />
				<mesh
					geometry={nodes.Cube003_16.geometry}
					material={materials["SVGMat"]}
					onClick={() => window.open("https://web3.aspo.games/", "_blank").focus()}
					onPointerMove={onMove}
					onPointerOut={onOut}
				/>
			</group>
		</group>
	);
}

const Home = () => {
	return (
		<div className="home">
			{/* <div className="whitepaper">
				<a href="/whitepaper">Whitepaper</a>
			</div> */}
			<div className="bg" />
			<h1>
				ASPO
				<br />
				<span>UNIVERSE</span>
			</h1>
			<Canvas dpr={[1.5, 2]} linear shadows>
				<fog attach="fog" args={["#272730", 16, 30]} />
				<ambientLight intensity={0.75} />
				<PerspectiveCamera makeDefault position={[0, 0, 16]} fov={75}>
					<pointLight intensity={1} position={[-10, -25, -10]} />
					<spotLight castShadow intensity={2.25} angle={0.2} penumbra={1} position={[-25, 20, -15]} shadow-mapSize={[1024, 1024]} shadow-bias={-0.0001} />
				</PerspectiveCamera>
				<Suspense fallback={null}>
					<Model url="/Ten_lua/Rocket_35.gltf" />
				</Suspense>
				<OrbitControls autoRotate enablePan={false} enableZoom={false} maxPolarAngle={Math.PI / 2} minPolarAngle={Math.PI / 2} />
				<Stars radius={500} depth={50} count={15000} factor={10} />
			</Canvas>
			<div className="layer" />
			<Loader />
		</div>
	);
};

export default Home;
